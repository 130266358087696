<template>
    <div id="Container">
        <div class="etc_modulo_container">
            <img src="../assets/website/global/logo_formate.svg" alt="Fórmate" class="etc_modulo_logo">
            <div class="etc_modulo_descripcion">
                <div class="etc_modulo_title">DESCUBRE LOS SESGOS COGNITIVOS</div>
                <div class="etc_modulo_parrafo">
                    <p>
                        Debido a la gran cantidad de información que consumimos en el día a día, nuestro cerebro utiliza atajos mentales o heurísticas para procesar de manera automática dicha información. Sin embargo, aunque resuelve problemas de forma rápida, nos puede llevar a cometer errores y percibir la realidad a través de juicios personales y conclusiones que podrían ser ilógicas.
                    </p><br><p>
                        A estos errores se le conocen cómo sesgos cognitivos y caemos en ellos con frecuencia cuando consumimos información falsa. Por eso, hemos diseñado para ti una serie de cómics que te ayudarán a identificarlos, entenderlos y evitarlos, para que juntos rompamos esa cadena de tanto cuento en internet.
                    </p>
                </div>
            </div>
        </div>

        <div id="atributos">
            <div class="atributo_contenedor" data-aos="zoom-in">
                <img src="../assets/website/formate/logo_anclaje.png" class="atributo_logo">
                <div class="atributo_titulo">
                    Anclaje
                </div>
                <div class="atributo_parrafo">
                    Este sesgo nos hace confiar demasiado en la primera información que recibimos sobre un tema. Si dicha información en la que confiamos es imprecisa o falsa puede distorsionar nuestra percepción y juicio, ocasionando confusión y discordia.
                </div>
            </div>

            <div class="atributo_contenedor" data-aos="zoom-in">
                <img src="../assets/website/formate/logo_confirmacion.png" class="atributo_logo">
                <div class="atributo_titulo">
                    Confirmación
                </div>
                <div class="atributo_parrafo">
                    Es la tendencia a buscar y dar mayor importancia a información que confirme nuestras creencias y preconcepciones, sin valorar otros puntos de vista y/o hechos que confirmen su veracidad y precisión. Este sesgo nos hace caer en miradas reducidas e incompletas de un tema.
                </div>
            </div>

            <div class="atributo_contenedor" data-aos="zoom-in">
                <img src="../assets/website/formate/logo_arrastre.png" class="atributo_logo">
                <div class="atributo_titulo">
                    Arrastre
                </div>
                <div class="atributo_parrafo">
                    Este sesgo nos lleva a adoptar comportamientos o creencias populares para sentirnos parte de un grupo. Si nos dejamos arrastrar por este sesgo anulamos nuestro pensamiento crítico, quedando a merced de la idea predomínate que en ocasiones puede ser perjudicial o irracional.
                </div>
            </div>

            <div class="atributo_contenedor" data-aos="zoom-in">
                <img src="../assets/website/formate/logo_rasgosobresaliente.png" class="atributo_logo">
                <div class="atributo_titulo">
                    Rastro Sobresaliente
                </div>
                <div class="atributo_parrafo">
                    Se refiere a la tendencia que tenemos de enfo-carnos en los hechos o características de la información que resultan más llamativos, dejando de lado información relevante para tomar decisiones o emitir juicios de valor sobre algún tema.
                </div>
            </div>
        </div>

        <div class="botonazul borderblue botonarriba">Cómics</div>
        <div class="etc_main_container">
            <splide id="splide" :slides="slides" :options="options" @splide:mounted="mounted" @splide:click="opencomic" @splide:move="movecomic">
                <splide-slide v-for="slide in slides" :key="slide.src">
                    <img class="imagen_carrusel" :src="slide.src">
                </splide-slide>
            </splide>
        </div>
        <div class="botonazul borderblue botonabajo">Webinar</div>

        <div class="etc_modulo_container">
            <!--div class="webinar_container borderblue">
                <img src="../assets/website/Webinar3Dic.png" class="webinar_image">
                <div class="webinar_title">Efectos de la difusión de noticias falsas en redes sociales</div>
                <div class="webinar_parrafo"></div>
                <div class="webinar_fecha">
                    <p>3 de Diciembre</p>
                    <p>8:30 A.M.</p>
                </div>
                <div class="webinar_invitados">
                    <p>Invitado</p>
                    <p class="webinar_invitado">Jonathan Bock</p>
                    <p>Director Fundación para la Libertad de Prensa (FLIP)</p>
                </div>
                <div class="webinar_boton"><a href="https://youtu.be/NMW0bCFvyEw" target="_blank">Ir al Webinar</a></div>
            </div>
            <div class="webinar_container borderblue">
                <img src="../assets/website/Webinar15Dic.png" class="webinar_image">
                <div class="webinar_title">Clausura</div>
                <div class="webinar_parrafo"></div>
                <div class="webinar_fecha">
                    <p>15 de Diciembre</p>
                    <p>11:00 A.M.</p>
                </div>
                <div class="webinar_invitados">
                    <p>Invitados</p>
                    <p class="webinar_invitado">Mónica M Hernández</p>
                    <p>Directora Fundación Telefónica en Colombia</p>
                    <p class="webinar_invitado">David Escobar Arango</p>
                    <p>Director Comfama</p>
                </div>
                <div class="webinar_boton"><a href="https://youtu.be/ddoUVP56gsU" target="_blank">Ir al Webinar</a></div>
            </div-->
        </div>

        <div class="titulo_azul">Revive el Prelanzamiento</div>
        <div class="parrafo">
            Jaime Abello, Director de la Fundación Gabo y Mónica Hernandez, Directora de la Fundación Telefónica en Colombia,  nos comparten sus percepciones acerca de las problemáticas que genera la difusión de noticias falsas y la desinformación que vivimos en la actualidad.
        </div>

        <div id="webinar_container">
            <div id="webinar_lanzamiento">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/OWx9HcuJs9g" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>

        <div class="titulo_azul">Efectos difusión de noticias falsas en redes sociales</div>
        <div class="parrafo">
            Te invitamos a explorar el impacto que tiene la información falsa en la actualidad y porqué el uso desproporcionado y acelerado de las redes sociales puede agravar la situación. Para hablar sobre ello nos acompaña, Jonathan Bock, Director Ejecutivo de la FLIP - Fundación para la Libertad de Prensa.
        </div>

        <div id="webinar_container">
            <div id="webinar_lanzamiento">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/NMW0bCFvyEw" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>

        <div class="titulo_azul">Fake News: un virus en redes sociales</div>
        <div class="parrafo">
            Para cerrar esta primera edición, queremos hablar de la importancia de ser ciudadanos digitales empoderados, con pensamiento crítico, para que podamos hacer reflexiones profundas sobre la responsabilidad compartida que tenemos para frenar este fenómeno de la desinformación.
        </div>

        <div id="webinar_container">
            <div id="webinar_lanzamiento">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/ddoUVP56gsU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>

        <div id="bottom_spacer"></div>

        <div id="comic-view">
            <div class="overlay" @click="closecomic">
                <a href="#" id="comic-big-link" target="blank"><img id="comic-big"></a>
            </div>
        </div>
    </div>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import '@splidejs/splide/dist/css/themes/splide-skyblue.min.css'

export default {
  components: {
    Splide,
    SplideSlide
  },
  data () {
    return {
      options: {
        type: 'loop',
        perPage: 3,
        focus: 'center',
        height: '45vh',
        width: '1200px',
        breakpoints: {
          900: {
            perPage: 1
          }
        }
      },
      slides: [
        { src: 'https://entretantocuento.nyc3.cdn.digitaloceanspaces.com/comic/arrastre.jpg' },
        { src: 'https://entretantocuento.nyc3.cdn.digitaloceanspaces.com/comic/confirmacion.jpg' },
        { src: 'https://entretantocuento.nyc3.cdn.digitaloceanspaces.com/comic/anclaje.jpg' },
        { src: 'https://entretantocuento.nyc3.cdn.digitaloceanspaces.com/comic/rasgoSobresaliente.jpg' },
        { src: 'https://entretantocuento.nyc3.cdn.digitaloceanspaces.com/comic/signosAdvertencia.png' },
        { src: 'https://entretantocuento.nyc3.cdn.digitaloceanspaces.com/comic/deberiaCompartir.png' }
      ]
    }
  },
  methods: {
    mounted: function () {
      const comicbig = document.querySelector('#comic-big')
      comicbig.src = this.slides[0].src

      const comicbiglink = document.querySelector('#comic-big-link')
      comicbiglink.href = this.slides[0].src
    },
    opencomic: function (s, e) {
      if (e.slide.classList.contains('is-active')) {
        const comicview = document.querySelector('#comic-view')
        comicview.style.display = 'block'
      }
    },
    closecomic: function () {
      const comicview = document.querySelector('#comic-view')
      comicview.style.display = 'none'
    },
    movecomic: function (s, newIndex) {
      const comicbig = document.querySelector('#comic-big')
      comicbig.src = this.slides[newIndex].src

      const comicbiglink = document.querySelector('#comic-big-link')
      comicbiglink.href = this.slides[newIndex].src
    }
  }
}
</script>

<style scoped>
a {
  color: inherit;
  text-decoration: inherit;
}
#comic-view {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
}
.overlay {
    background: rgba(51,51,51,0.7);
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 999999;

    display: flex;
    align-items: center;
    justify-content: center;
}
#comic-big {
    height: 90vh;
    width: auto;
    border-radius: 15px;
    cursor: zoom-in;
}

#splide {
    margin: 0px auto;
}
#splide .is-active :hover {
    cursor: pointer;
}
.imagen_carrusel {
    width: auto;
    height: 30vh;
    border-radius: 15px;
}
.is-active > img {
    height: 42vh;
}

.webinar_container {
    background-color: #54ceea;
    position: relative;
    padding: 20px;
    margin: 40px;
    width: 300px;
    height: 470px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.webinar_image {
    width: 250px;
    height: auto;
    margin-bottom: 10px;
}
.webinar_title {
    font: 28px "Bebas Neue";
}
.webinar_parrafo {
    font: 24px "Helvetica";
    text-align: justify;
}
.webinar_fecha {
    color: white;
    font: 24px "Bebas Neue";
}
.webinar_invitado {
    font-size: 20px;
    margin-top: 5px;
}
.webinar_boton {
    background-color: #0C1D3E;
    border: 2px solid #54ceea;
    border-radius: 15px;
    font: 28px "Bebas Neue";
    color: white;
    padding: 5px 20px;
    margin-bottom: -40px;
    z-index: 1;
}

#atributos {
    display: flex;
    flex-flow: wrap;
    justify-content: space-around;
    align-items: center;

    padding: 0px 0px 40px;
    max-width: 1000px;
    margin: 0px auto;
}
.atributo_contenedor {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 450px;
    min-width: 300px;
    margin: 20px;
}
.atributo_logo {
    height: 10vh;
}
.atributo_titulo {
    font: 32px "Bebas Neue";
    color: #54ceea;
    margin-top: 10px;
}
.atributo_parrafo {
    width: 80%;
    padding: 20px;
}

.titulo_azul {
    font: 48px "Bebas Neue";
    color: #0C1D3E;
    margin: 60px 0px 10px;
}
.parrafo {
    width: 60%;
    max-width: 800px;
    padding: 20px;
    margin: 0px auto;
    font: 20px "Helvetica";
}

#webinar_container {
    width: 50%;
    margin: 0px auto 25px;
    padding: 20px;
    border: 2px solid #0C1D3E;
    border-radius: 15px;
}
#webinar_lanzamiento {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
#webinar_lanzamiento iframe {
  border-radius: 15px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#bottom_spacer {
    height: 50px;
}

@media screen and (max-width: 820px) {
    #comic-big {
        width: 90%;
        height: auto;
    }

    #webinar_container {
        width: 90%;
    }

    .atributo_parrafo {
        padding: 0px;
    }

    .parrafo {
        width: 80%;
        padding: 20px 0px;
    }
}
</style>
